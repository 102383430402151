import { useTranslation } from 'react-i18next';
import { ColumnType, FilterConfirmProps, SortOrder } from 'antd/lib/table/interface';
import { ColumnSearchTranslationsType, DeviceDataIndex, getColumnSearchProps } from '../SearchPeripheralColumns';
import { CustomDeviceModel } from 'hooks/useAssetDevices';
import { Icon } from 'components/atoms/Icons';
import { COLORS } from 'constants/colors';
import ContextualMenu from 'components/atoms/ContextualMenu';
import MenuAtom from 'components/atoms/MenuAtom';
import './styles.scss';

export interface ColumnsPeripheralDevicesOptionsModel {
  columnSearchTranslations?: ColumnSearchTranslationsType;
  searchInput?: any;
  onSearch?: (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => void;
  onReset?: (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => void;
  onOpenMaintenanceActionsDrawer: (device: CustomDeviceModel) => void;
  onOpenUpdateDeviceDrawer: (device: CustomDeviceModel) => void;
  onOpenDeleteDeviceModal: (device: CustomDeviceModel) => void;
}

interface Props {
  options: ColumnsPeripheralDevicesOptionsModel;
}

interface SortPeripheralsByBatteryLevelProps {
  a: number | null,
  b: number | null,
  sortOrder?: SortOrder,
}

export const sortPeripherals = (a: string, b: string) => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

export const sortPeripheralsByBatteryLevel = ({ a, b, sortOrder }: SortPeripheralsByBatteryLevelProps) => {
  if (a === null) return sortOrder === 'ascend' ? 1 : -1;
  if (b === null) return sortOrder === 'ascend' ? -1 : 1;
  return a - b;
};

const sortVirtualGateways = (a: boolean, b: boolean) => {
  if (a === b) return 0;
  return a ? -1 : 1;
}

export const ColumnsPeripheralDevices = ({ options }: Props): ColumnType<CustomDeviceModel>[] => {
  const { t } = useTranslation();
  const {
    columnSearchTranslations,
    searchInput,
    onSearch,
    onReset,
    onOpenMaintenanceActionsDrawer,
    onOpenUpdateDeviceDrawer,
    onOpenDeleteDeviceModal,
  } = options;

  return [
    {
      title: 'Id',
      key: 'deviceId',
      width: '10%',
      sorter: (a, b) => Number(a.deviceId) - Number(b.deviceId),
      sortDirections: ['ascend', 'descend'],
      dataIndex: 'deviceId',
    },
    {
      title: t('type'),
      key: 'deviceType',
      width: '25%',
      filterIcon: (filtered: boolean) => (
        <Icon.Search style={{ color: filtered ? COLORS.corporate : undefined }} />
      ),
      sorter: (a, b) => sortPeripherals(a.deviceType, b.deviceType),
      ...getColumnSearchProps({ dataIndex: 'deviceType', onSearch, onReset, searchInput, translations: columnSearchTranslations }),
    },
    {
      title: t('name'),
      key: 'deviceName',
      width: '25%',
      filterIcon: (filtered: boolean) => (
        <Icon.Search style={{ color: filtered ? COLORS.corporate : undefined }} />
      ),
      ...getColumnSearchProps({ dataIndex: 'deviceName', onSearch, onReset, searchInput, translations: columnSearchTranslations }),
    },
    {
      title: t('remaining_battery'),
      key: 'batteryLevel',
      width: '15%',
      render: ({ batteryLevel }) => {
        return batteryLevel !== null
          ? <span>{`${batteryLevel}%`}</span>
          : <span className='Column__notReportedText'>{t('not_reported')}</span>
      },
      sorter: (a, b, sortOrder) => sortPeripheralsByBatteryLevel({ a: a.batteryLevel, b: b.batteryLevel, sortOrder }),
    },
    {
      title: t('_DEVICES_COLUMNS_LINKED_REAL_GATEWAY_TITLE'),
      key: 'isVirtualGateway',
      width: '15%',
      render: ({ isVirtualGateway }) => {
        return <span>{isVirtualGateway ? t('_DEVICES_COLUMNS_HAS_NOT_LINKED_REAL_GATEWAY_TEXT') : t('_DEVICES_COLUMNS_LINKED_REAL_GATEWAY_TEXT')}</span>
      },
      sorter: (a, b) => sortVirtualGateways(a.isVirtualGateway, b.isVirtualGateway),
    },
    {
      title: t('actions'),
      key: 'menuExtra',
      width: '10%',
      render: (values) => {
        return <ContextualMenu menu={(
          <MenuAtom items={[
            {
              key: 'maintenanceActions',
              label: t('maintenance_actions'),
              onClick: () => onOpenMaintenanceActionsDrawer(values),
            },
            values.isVirtualGateway && {
              key: 'updateDevice',
              label: t('_UPDATE_DEVICE_WITHOUT_GATEWAY_BUTTON_TEXT'),
              onClick: () => onOpenUpdateDeviceDrawer(values),
            },
            values.isVirtualGateway && {
              key: 'deleteDevice',
              label: t('_DELETE_DEVICE_WITHOUT_GATEWAY_BUTTON_TEXT'),
              onClick: () => onOpenDeleteDeviceModal(values),
              danger: true,
            }
          ]} />
        )} />
      },
    },
  ];
};
