import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getAsset } from 'services/api/assets';
import { ExpandedPeripheralDevicesTranslations } from 'components/organisms/PeripheralDevices/ExpandedColumnsPeripheralsDevices';
import { useAssetDevices } from 'hooks/useAssetDevices';
import { useCreateDeviceWithoutGateway } from './useCreateDeviceWithoutGateway';
import { useUpdateDeviceWithoutGateway } from './useUpdateDeviceWithoutGateway';

export const useTabDevices = () => {
  const { t } = useTranslation();
  const { assetId } = useParams<{ assetId: string }>();
  const [loading, setLoading] = useState(true);
  const [gateway, setGateway] = useState<string>();
  const { createDeviceOptions, successCreatingDevice } = useCreateDeviceWithoutGateway();
  const { updateDeviceOptions, successUpdatingDevice } = useUpdateDeviceWithoutGateway();
  const { devices, device, columns, deleteDeviceModalOptions, maintenanceActionsDrawerOptions, getDevices, updateGateway, loadingDevices } =
    useAssetDevices(assetId);

  const peripheralExpandedColumnsTranslations: ExpandedPeripheralDevicesTranslations = {
    nameText: t('name'),
    roomText: t('room'),
    typeText: t('type'),
    usageText: t('usage'),
    notReportedText: t('not_reported'),
  };

  const getAssetLinkedGateways = async (): Promise<string[]> => {
    try {
      const { data } = await getAsset(assetId);
      return !!data ? data.gateways_qrs.map((gateway) => gateway.gateway_id) : [];
    } catch (error) {
      console.warn(error);
      return [];
    }
  };

  const getGatewaysListData = async () => {
    setLoading(true);
    try {
      const linkedGateways = await getAssetLinkedGateways();
      !!linkedGateways.length && setGateway(linkedGateways[0]);
    } catch (error) {
      console.warn(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGatewaysListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  useEffect(() => {
    (!!successCreatingDevice || !!successUpdatingDevice) && getDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successCreatingDevice, successUpdatingDevice]);

  useEffect(() => {
    !!gateway && updateGateway(gateway);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gateway]);

  useEffect(() => {
    !!device && updateDeviceOptions.onOpenUpdateDeviceDrawer(device);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device]);

  return {
    devices,
    gateway,
    columns,
    peripheralExpandedColumnsTranslations,
    createDeviceOptions,
    updateDeviceOptions,
    deleteDeviceModalOptions,
    maintenanceActionsDrawerOptions,
    loading: loading || loadingDevices,
  };
};
