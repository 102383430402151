import {
  DevicesListWithoutGatewayResponseModel,
  DevicesListWithoutGatewayResponsePayload,
  DeviceTypeWithoutGatewayDataModel,
  DeviceTypeWithoutGatewayDataPayload,
  DeviceTypeWithoutGatewayDataSensorModel,
  DeviceTypeWithoutGatewayDataSensorPayload,
  DeviceTypeWithoutGatewayMetaModel,
  DeviceTypeWithoutGatewayMetaPayload,
  DeviceTypeWithoutGatewayResponseModel,
  DeviceTypeWithoutGatewayResponsePayload,
  DeviceWithoutGatewayCreateModel,
  DeviceWithoutGatewayCreatePayload,
  DeviceWithoutGatewayModel,
  DeviceWithoutGatewayParameterDataModel,
  DeviceWithoutGatewayParameterDataPayload,
  DeviceWithoutGatewayParameterModel,
  DeviceWithoutGatewayParameterPayload,
  DeviceWithoutGatewayPayload,
  DeviceWithoutGatewayResponseModel,
  DeviceWithoutGatewayResponsePayload,
  DeviceWithoutGatewaySensorCreateModel,
  DeviceWithoutGatewaySensorCreatePayload,
  DeviceWithoutGatewaySensorModel,
  DeviceWithoutGatewaySensorPayload,
  DeviceWithoutGatewayUpdateModel,
  DeviceWithoutGatewayUpdatePayload,
} from '../models';

const payloadToDeviceTypeWithoutGatewayDataSensor = ({
  label,
  sensor_type,
}: DeviceTypeWithoutGatewayDataSensorPayload): DeviceTypeWithoutGatewayDataSensorModel => {
  return {
    label: label ?? '',
    sensorType: sensor_type ?? '',
  };
};

const payloadToDeviceTypeWithoutGatewayData = ({
  name,
  device_type,
  sensors,
}: DeviceTypeWithoutGatewayDataPayload): DeviceTypeWithoutGatewayDataModel => {
  const transformedSensors = sensors.map((item) => payloadToDeviceTypeWithoutGatewayDataSensor(item)) ?? [];
  return {
    name: name ?? '',
    deviceType: device_type ?? '',
    sensors: transformedSensors,
  };
};

const payloadToDeviceTypeWithoutGatewayMeta = ({
  all_results,
  page_size,
  results,
  total_pages,
}: DeviceTypeWithoutGatewayMetaPayload): DeviceTypeWithoutGatewayMetaModel => {
  return {
    allResults: all_results ?? 1,
    pageSize: page_size ?? 1,
    results: results ?? 1,
    totalPages: total_pages ?? 1,
  };
};

export const payloadToDevicesTypesListWithoutGateways = ({
  data,
  meta,
  links,
}: DeviceTypeWithoutGatewayResponsePayload): DeviceTypeWithoutGatewayResponseModel => {
  const transformedData = data.map((item) => payloadToDeviceTypeWithoutGatewayData(item)) ?? [];
  const transformedMeta = payloadToDeviceTypeWithoutGatewayMeta(meta);

  return {
    data: transformedData,
    meta: transformedMeta,
    links: {
      first: links.first ?? '',
      last: links.last ?? '',
      next: links.next ?? '',
      prev: links.prev ?? '',
      self: links.self ?? '',
    },
  };
};

const payloadToDeviceWithoutGatewayParameterDataPayload = ({
  value,
  unit,
}: DeviceWithoutGatewayParameterDataPayload): DeviceWithoutGatewayParameterDataModel => {
  const transformedValue = typeof value === 'number' ? 0 : '';
  return {
    value: value ?? transformedValue,
    unit: unit ?? '',
  };
};

const payloadToDeviceWithoutGatewayParameter = ({
  data,
  multiple,
  name,
  required,
}: DeviceWithoutGatewayParameterPayload): DeviceWithoutGatewayParameterModel => {
  const transformedData = data.map((item) => payloadToDeviceWithoutGatewayParameterDataPayload(item)) ?? [];

  return {
    data: transformedData,
    multiple: multiple ?? false,
    name: name ?? '',
    required: required ?? false,
  };
};

const payloadToDeviceWithoutGatewaySensor = ({
  label,
  name,
  room,
  sensor_id,
  sensor_type,
  sensor_uuid,
}: DeviceWithoutGatewaySensorPayload): DeviceWithoutGatewaySensorModel => {
  return {
    label: label ?? '',
    name: name ?? null,
    room: room ?? '',
    sensorId: sensor_id ?? '',
    sensorType: sensor_type ?? '',
    sensorUuid: sensor_uuid ?? '',
  };
};

const payloadToDeviceDetailWithoutGateway = ({
  config_parameters,
  device_type,
  device_uuid,
  name,
  room,
  secret_key,
  serial_number,
  asset_id,
  sensors,
}: DeviceWithoutGatewayPayload): DeviceWithoutGatewayModel => {
  const transformedConfigParameters = config_parameters?.map((item) => payloadToDeviceWithoutGatewayParameter(item)) ?? [];
  const transformedSensors = sensors.map((item) => payloadToDeviceWithoutGatewaySensor(item)) ?? [];

  return {
    configParameters: transformedConfigParameters,
    deviceType: device_type ?? '',
    deviceUuid: device_uuid ?? '',
    name: name ?? '',
    room: room ?? '',
    secretKey: secret_key ?? '',
    serialNumber: serial_number ?? '',
    assetId: asset_id ?? '',
    sensors: transformedSensors,
  };
};

export const payloadToDeviceDetailWithoutGatewayResponse = ({
  data,
}: DeviceWithoutGatewayResponsePayload): DeviceWithoutGatewayResponseModel => {
  const transformedData = payloadToDeviceDetailWithoutGateway(data);

  return {
    data: transformedData,
  };
};

const deviceParameterDataToPayload = ({
  value,
  unit,
}: DeviceWithoutGatewayParameterDataModel): DeviceWithoutGatewayParameterDataPayload => {
  return { value, unit };
};

const deviceParameterToPayload = ({
  data,
  multiple,
  name,
  required,
}: DeviceWithoutGatewayParameterModel): DeviceWithoutGatewayParameterPayload => {
  const transformedData = !!data.length ? data.map((item) => deviceParameterDataToPayload(item)) : [];
  return {
    data: transformedData,
    multiple,
    name,
    required,
  };
};

const deviceSensorToPayload = ({
  label,
  name,
  room,
  sensorId,
  sensorType,
  sensorUuid,
}: DeviceWithoutGatewaySensorModel): DeviceWithoutGatewaySensorPayload => {
  return {
    label,
    name,
    room,
    sensor_id: sensorId,
    sensor_type: sensorType,
    sensor_uuid: sensorUuid,
  };
};

export const deviceWithoutGatewayToPayload = ({
  configParameters,
  deviceType,
  deviceUuid,
  name,
  room,
  secretKey,
  serialNumber,
  assetId,
  sensors,
}: DeviceWithoutGatewayModel): DeviceWithoutGatewayPayload => {
  const transformedConfigParameters =
    !!configParameters && !!configParameters.length ? configParameters.map((item) => deviceParameterToPayload(item)) : null;
  const transformedSensors = sensors.map((item) => deviceSensorToPayload(item)) ?? [];

  return {
    config_parameters: transformedConfigParameters,
    device_type: deviceType,
    device_uuid: deviceUuid,
    name,
    room,
    secret_key: secretKey,
    serial_number: serialNumber,
    asset_id: assetId,
    sensors: transformedSensors,
  };
};

const deviceWithoutGatewaySensorCreateToPayload = ({
  label,
  name,
  room,
  sensorType,
}: DeviceWithoutGatewaySensorCreateModel): DeviceWithoutGatewaySensorCreatePayload => {
  return {
    label,
    name,
    room,
    sensor_type: sensorType,
  };
};

export const deviceWithoutGatewayCreateToPayload = ({
  configParameters,
  deviceType,
  name,
  room,
  secretKey,
  serialNumber,
  assetId,
  sensors,
}: DeviceWithoutGatewayCreateModel): DeviceWithoutGatewayCreatePayload => {
  const transformedConfigParameters = configParameters?.length ? configParameters.map((item) => deviceParameterToPayload(item)) : null;
  const transformedSensors = sensors.map((item) => deviceWithoutGatewaySensorCreateToPayload(item)) ?? [];

  return {
    config_parameters: transformedConfigParameters,
    device_type: deviceType,
    name,
    room,
    asset_id: assetId,
    secret_key: secretKey,
    serial_number: serialNumber,
    sensors: transformedSensors,
  };
};

const deviceWithoutGatewaySensorUpdateToPayload = ({
  label,
  name,
  room,
  sensorId,
  sensorType,
  sensorUuid,
}: DeviceWithoutGatewaySensorModel): DeviceWithoutGatewaySensorPayload => {
  return {
    label,
    name,
    room,
    sensor_id: sensorId,
    sensor_type: sensorType,
    sensor_uuid: sensorUuid,
  };
};

export const deviceWithoutGatewayUpdateToPayload = ({
  configParameters,
  deviceType,
  name,
  room,
  secretKey,
  serialNumber,
  assetId,
  sensors,
}: DeviceWithoutGatewayUpdateModel): DeviceWithoutGatewayUpdatePayload => {
  const transformedConfigParameters = configParameters?.length ? configParameters.map((item) => deviceParameterToPayload(item)) : null;
  const transformedSensors = sensors.map((item) => deviceWithoutGatewaySensorUpdateToPayload(item)) ?? [];

  return {
    config_parameters: transformedConfigParameters,
    device_type: deviceType,
    name,
    room,
    asset_id: assetId,
    secret_key: secretKey,
    serial_number: serialNumber,
    sensors: transformedSensors,
  };
};

const payloadToDeviceWithoutGatewayMeta = ({
  all_results,
  page_size,
  results,
  total_pages,
}: DeviceTypeWithoutGatewayMetaPayload): DeviceTypeWithoutGatewayMetaModel => {
  return {
    allResults: all_results ?? 1,
    pageSize: page_size ?? 1,
    results: results ?? 1,
    totalPages: total_pages ?? 1,
  };
};

export const payloadToDevicesListWithoutGatewayResponse = ({
  data,
  links,
  meta,
}: DevicesListWithoutGatewayResponsePayload): DevicesListWithoutGatewayResponseModel => {
  const transformedData = data.map((item) => payloadToDeviceDetailWithoutGateway(item)) ?? [];
  const transformedMeta = payloadToDeviceWithoutGatewayMeta(meta);

  return {
    data: transformedData,
    meta: transformedMeta,
    links: {
      first: links.first ?? '',
      last: links.last ?? '',
      next: links.next ?? '',
      prev: links.prev ?? '',
      self: links.self ?? '',
    },
  };
};
