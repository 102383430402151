import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorFormat } from 'core/utils/errors';
import { DevicesListWithoutGatewayResponseModel, DevicesListWithoutGatewayResponsePayload } from '../models';
import { payloadToDevicesListWithoutGatewayResponse } from '../mappers';

const getUrl = ({
  host,
  pageNumber,
  pageSize,
  search,
  assetId,
  projectId,
  clientId,
}: {
  host: string;
  pageNumber: number;
  pageSize: number;
  search?: string;
  assetId?: string;
  projectId?: string;
  clientId?: string;
}) => {
  const params = new URLSearchParams();
  pageNumber && params.append('page[number]', String(pageNumber));
  pageSize && params.append('page[size]', String(pageSize));
  assetId && params.append('asset_id', String(assetId));
  projectId && params.append('project_id', String(projectId));
  clientId && params.append('client_id', String(clientId));
  search && params.append('search', String(search));

  return `https://udp-testing.alfredsmartdata.com/devices?${params}`;
  // return `${host}/devices?${params}`;
};

export const GetDevicesListWithoutGateway = async ({
  token,
  host,
  pageNumber,
  pageSize,
  assetId,
  projectId,
  clientId,
  search,
}: {
  token: string;
  host: string;
  pageNumber: number;
  pageSize: number;
  assetId?: string;
  projectId?: string;
  clientId?: string;
  search?: string;
}): Promise<DevicesListWithoutGatewayResponseModel> => {
  try {
    const url = getUrl({ host, pageNumber, pageSize, search, assetId, projectId, clientId });

    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
    };

    const { data } = (await axios(options)) as AxiosResponse<DevicesListWithoutGatewayResponsePayload>;
    return payloadToDevicesListWithoutGatewayResponse(data);
  } catch (error) {
    throw getErrorFormat(error);
  }
};
