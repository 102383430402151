import { FC } from 'react';
import { DeviceModel, DeviceSensorModel } from 'core/domain/gateways/model';
import { TableColumnsType } from 'antd';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import TableAtom from 'components/atoms/TableAtom';
import { ColumnsPeripheralDevices } from './ColumnsPeripheralDevices';
import { ExpandedPeripheralDevicesColumns, ExpandedPeripheralDevicesTranslations } from './ExpandedColumnsPeripheraldevices';
import { ColumnSearchTranslationsType, DeviceDataIndex } from './SearchPeripheralColumns';
import './styles.scss'

interface PeripheralDevicesProps {
  expandedColumnsTranslations: ExpandedPeripheralDevicesTranslations;
  gatewayId?: string;
  devices?: DeviceModel[]
  loading?: boolean;
  searchInput: any;
  columnSearchTranslations?: ColumnSearchTranslationsType;
  onSearch?: (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => void;
  onReset?: (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => void;
  onOpenShareSensorModal: (sensorUUid: string) => void;
}

const PeripheralDevices: FC<PeripheralDevicesProps> = ({
  gatewayId,
  devices = [],
  loading = false,
  expandedColumnsTranslations,
  searchInput,
  columnSearchTranslations,
  onSearch,
  onReset,
  onOpenShareSensorModal,
}) => {

  const columns = ColumnsPeripheralDevices({ gatewayId, columnSearchTranslations, searchInput, onSearch, onReset });

  const expandedRowRender = ({ sensors }: DeviceModel) => {
    const expandedColumns: TableColumnsType<DeviceSensorModel> = ExpandedPeripheralDevicesColumns({ translations: expandedColumnsTranslations, onOpenShareSensorModal });
    return (
      <TableAtom<DeviceSensorModel>
        columns={expandedColumns}
        dataSource={sensors}
        pagination={false}
        rowKey={'sensorUuid'}
      />
    );
  };

  return (
    <TableAtom<DeviceModel>
      className={'PeripheralDevices__expanded-table-wrapper'}
      loading={loading}
      columns={columns}
      expandable={{ expandedRowRender }}
      rowKey={'deviceId'}
      dataSource={devices}
      pagination={false}
    />
  );
};

export default PeripheralDevices;
