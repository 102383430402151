import { FC } from 'react';
import DrawerAtom, { DrawerAtomProps } from 'components/atoms/DrawerAtom';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import { UpdateDeviceOptionsModel } from 'components/pages/AssetPage/Tabs/TabDevices/resources/useUpdateDeviceWithoutGateway';
import DeviceTypesVisorManager from 'components/pages/AssetPage/Tabs/TabDevices/resources/DeviceTypesVisorManager';
import './styles.scss';

export interface UpdateDeviceLiteralsType {
  updateDeviceButtonText: string;
  title: string;
  mainButtonText: string;
  secondaryButtonText: string;
}

export interface Props extends DrawerAtomProps {
  options: UpdateDeviceOptionsModel;
}

const DEVICE_SECTION_NUMBER = 1;
const SENSORS_SECTION_NUMBER = 2;

const UpdateDeviceWithoutGatewayDrawer: FC<Props> = ({ open, onClose, title, options }) => {
  const {
    updateDeviceLiterals,
    waterMeterWm1000nbOptions,
    formType,
    onUpdateDevice,
    updating,
  } = options
  const { mainButtonText, secondaryButtonText } = updateDeviceLiterals;

  return (
    <DrawerAtom
      className={'UpdateDeviceWithoutGatewayDrawer__wrapper'}
      open={open}
      onClose={onClose}
      size={'large'}
      title={<span className={'UpdateDeviceWithoutGatewayDrawer__title'}>{title}</span>}
    >
      <main className={'UpdateDeviceWithoutGatewayDrawer__content-wrapper'}>
        <div className={'UpdateDeviceWithoutGatewayDrawer__form'}>
          {!!formType && (
            <DeviceTypesVisorManager
              formType={formType}
              waterMeterWm1000nbOptions={waterMeterWm1000nbOptions}
              deviceSectionNumber={DEVICE_SECTION_NUMBER}
              sensorsSectionNumber={SENSORS_SECTION_NUMBER}
            />)}
        </div>
      </main>
      <footer>
        <DefaultButton onClick={onClose} title={secondaryButtonText} />
        <PrimaryButton loading={updating} title={mainButtonText} onClick={onUpdateDevice} />
      </footer>
    </DrawerAtom>
  );
}

export default UpdateDeviceWithoutGatewayDrawer;