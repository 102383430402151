import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorFormat } from 'core/utils/errors';
import { DeviceWithoutGatewayUpdateModel } from '../models';
import { deviceWithoutGatewayUpdateToPayload } from '../mappers';

const getUrl = ({ host, deviceId }: { host: string; deviceId: string }) => {
  return `https://udp-testing.alfredsmartdata.com/devices/${deviceId}`;
  // return `${host}/devices/${deviceId}`;
};

export const UpdateDeviceDetailWithoutGateway = async ({
  token,
  host,
  device,
  deviceId,
}: {
  token: string;
  host: string;
  device: DeviceWithoutGatewayUpdateModel;
  deviceId: string;
}): Promise<void> => {
  try {
    const url = getUrl({ host, deviceId });

    const mappedData = deviceWithoutGatewayUpdateToPayload(device);

    const options: AxiosRequestConfig = {
      url,
      method: 'PATCH',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
      data: { data: mappedData },
    };

    const { data } = (await axios(options)) as AxiosResponse<void>;
    return data;
  } catch (error) {
    throw getErrorFormat(error);
  }
};
