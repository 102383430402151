import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { getGatewayStatusGID } from 'services/api/gateways';
import { ColumnSearchTranslationsType, DeviceDataIndex } from 'components/organisms/PeripheralDevices/SearchPeripheralColumns';
import { DeviceModel, DeviceSensorModel } from 'core/domain/gateways/model';
import { GetAssetDevices } from 'core/domain/assets/repositories/getAssetDevices';
import { useEnvironment } from './useEnvironment';
import { useUserSession } from './useUserSession';
import { useMessage } from './useMessage';
import { GetDevicesListWithoutGateway } from 'core/domain/devices/repositories/getDevicesListWithoutGateway';
import { TABLE_PAGINATION_PAGE_DEFAULT } from 'constants/table';
import { DeviceWithoutGatewayModel } from 'core/domain/devices/models';
import { DeleteDeviceDetailWithoutGateway } from 'core/domain/devices/repositories/deleteDeviceWithoutGateway';
import {
  ColumnsPeripheralDevices,
  ColumnsPeripheralDevicesOptionsModel,
} from 'components/organisms/PeripheralDevices/ColumnsPeripheralDevices';
import { Device, DeviceSensor } from 'models/devices.model';

export interface CustomDeviceDataToBeDeletedModel {
  deviceId: string;
  name: string;
}

export interface CustomDeviceModel extends DeviceModel {
  isVirtualGateway: boolean;
}

export const useAssetDevices = (assetId: string) => {
  const { t } = useTranslation();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError, setMessageSuccess } = useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [devices, setDevices] = useState<CustomDeviceModel[]>([]);
  const [device, setDevice] = useState<CustomDeviceModel | null>(null);
  const [deviceToBeDeleted, setDeviceToBeDeleted] = useState<CustomDeviceDataToBeDeletedModel | null>(null);
  const [maintenanceDevice, setMaintenanceDevice] = useState<Device>();
  const [gateway, setGateway] = useState<string>();
  const [searchedColumn, setSearchedColumn] = useState<string>('');
  const [isMaintenanceActionsDrawerVisible, setIsMaintenanceActionsDrawerVisible] = useState<boolean>(false);
  const [deleteDeviceModalVisible, setDeleteDeviceModalVisible] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);

  const isMounted = useRef(false);
  const searchInput = useRef<any>(null);

  const columnSearchTranslations: ColumnSearchTranslationsType = {
    closeButtonText: t('close'),
    resetButtonText: t('_RESET'),
    searchButtonText: t('_SEARCH'),
    placeholder: t('_PLACEHOLDER_GENERAL'),
    notReportedText: t('not_reported'),
  };

  const deleteDeviceModalTranslations = {
    title: t('_DELETE_DEVICE_WITHOUT_GATEWAY_BUTTON_TEXT'),
    text: t('_DELETE_DEVICE_WITHOUT_GATEWAY_BUTTON_TEXT_CONFIRMATION', { name: deviceToBeDeleted?.name }),
    mainButtonText: t('delete'),
    secondaryButtonText: t('cancel'),
  };

  const onSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => {
    confirm({ closeDropdown: false });
    setSearchedColumn(dataIndex);
  };

  const onReset = (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => {
    clearFilters();
    onSearch([''], confirm, dataIndex);
  };

  const transformDevices = (
    devicesWithGateway: DeviceModel[],
    devicesWithoutGateways: DeviceWithoutGatewayModel[]
  ): CustomDeviceModel[] => {
    return devicesWithGateway.map((device) => {
      const isVirtualGateway = devicesWithoutGateways.some((deviceWithoutGateway) =>
        deviceWithoutGateway.deviceUuid.includes(device.deviceId)
      );
      return { ...device, isVirtualGateway };
    });
  };

  const getDevices = useCallback(async () => {
    setIsLoading(true);
    try {
      const { gatewayId } = await getGatewayStatusGID(assetId);
      if (gatewayId) {
        const devicesResponse = await GetAssetDevices({ host, token, assetId });
        const { data: devicesWithoutGateways } = await GetDevicesListWithoutGateway({
          host,
          token,
          pageNumber: Number(TABLE_PAGINATION_PAGE_DEFAULT),
          pageSize: 500,
          assetId,
        });
        const devices = devicesResponse.gateways.flatMap(({ devices }) => devices);
        const transformedDevices = transformDevices(devices, devicesWithoutGateways);
        isMounted.current && setDevices(transformedDevices);
      }
    } catch (error) {
      setMessageError({ description: t('_DEVICES_LIST_ERROR_MESSAGE') });
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  const transformSensorToMaintenanceSensor = ({
    itemName,
    itemType,
    sensorId,
    sensorType,
    name,
    room,
    usage,
  }: DeviceSensorModel): DeviceSensor => {
    return {
      item_name: itemName,
      item_type: itemType,
      sensor_id: sensorId,
      sensor_type: sensorType,
      name,
      room,
      usage,
    };
  };

  const transformDeviceToMaintenanceDevice = ({
    availableActions,
    batteryLevel,
    deviceId,
    deviceName,
    deviceType,
    enabled,
    parameters,
    password,
    sensors,
    thingId,
    lockType,
    thingTypeUid,
  }: CustomDeviceModel): Device => {
    const transformedSensors = sensors.map((sensor) => transformSensorToMaintenanceSensor(sensor)) ?? [];
    return {
      available_actions: availableActions,
      battery_level: batteryLevel,
      device_id: deviceId,
      device_name: deviceName,
      device_type: deviceType,
      enabled,
      parameters,
      password,
      sensors: transformedSensors,
      thing_id: thingId,
      lock_type: lockType,
      thing_type_uid: thingTypeUid,
    };
  };

  const onOpenMaintenanceActionsDrawer = (selectedDevice: CustomDeviceModel) => {
    setIsMaintenanceActionsDrawerVisible(true);
    const newMaintenanceDevice = transformDeviceToMaintenanceDevice(selectedDevice);
    setMaintenanceDevice(newMaintenanceDevice);
  };

  const onCloseMaintenanceActionsDrawer = () => {
    setIsMaintenanceActionsDrawerVisible(false);
    setMaintenanceDevice(undefined);
  };

  const onOpenDeleteDeviceModal = ({ deviceId, deviceName }: CustomDeviceModel) => {
    setDeviceToBeDeleted({ deviceId, name: deviceName });
  };

  const onCloseDeleteDeviceModal = () => {
    setDeleteDeviceModalVisible(false);
    setDeviceToBeDeleted(null);
  };

  const onDeleteDevice = async () => {
    try {
      setDeleting(true);
      await DeleteDeviceDetailWithoutGateway({ host, token, deviceId: deviceToBeDeleted?.deviceId || '' });
      setMessageSuccess({ description: t('_DELETE_DEVICE_WITHOUT_GATEWAY_SUCCESS_MESSAGE') });
      onCloseDeleteDeviceModal();
      await getDevices();
    } catch (error) {
      setMessageError({ description: t('_DELETE_DEVICE_WITHOUT_GATEWAY_ERROR_MESSAGE') });
    } finally {
      setDeleting(false);
    }
  };

  const onUpdateDevice = (selectedDevice: CustomDeviceModel) => {
    setDevice(selectedDevice);
  };

  const columnsOptions: ColumnsPeripheralDevicesOptionsModel = {
    columnSearchTranslations,
    searchInput,
    onSearch,
    onReset,
    onOpenMaintenanceActionsDrawer,
    onOpenUpdateDeviceDrawer: onUpdateDevice,
    onOpenDeleteDeviceModal,
  };

  const columns = ColumnsPeripheralDevices({ options: columnsOptions });

  const updateGateway = (newGateway: string) => {
    setGateway(newGateway);
  };

  useEffect(() => {
    isMounted.current = true;
    getDevices().finally(() => {
      if (isMounted.current) {
        setIsLoading(false);
      }
    });

    return () => {
      isMounted.current = false;
    };
  }, [assetId, getDevices]);

  useEffect(() => {
    !!deviceToBeDeleted && setDeleteDeviceModalVisible(true);
  }, [deviceToBeDeleted]);

  useEffect(() => {
    !!device && setDevice(null);
  }, [device]);

  const maintenanceActionsDrawerOptions = {
    gatewayId: gateway,
    maintenanceDevice,
    onCloseMaintenanceActionsDrawer,
    isMaintenanceActionsDrawerVisible,
  };

  const deleteDeviceModalOptions = {
    deleteDeviceModalTranslations,
    onDeleteDevice,
    onCloseDeleteDeviceModal,
    deleteDeviceModalVisible,
    deleting,
  };

  return {
    devices,
    loadingDevices: isLoading,
    searchedColumn,
    columns,
    device,
    deleteDeviceModalOptions,
    maintenanceActionsDrawerOptions,
    getDevices,
    updateGateway,
    onUpdateDevice,
  };
};
