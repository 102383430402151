import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorFormat } from 'core/utils/errors';
import { DeviceWithoutGatewayCreateModel } from '../models';
import { deviceWithoutGatewayCreateToPayload } from '../mappers';

const getUrl = (host: string) => {
  return 'https://udp-testing.alfredsmartdata.com/devices';
  // return `${host}/devices`;
};

export const CreateDeviceDetailWithoutGateway = async ({
  token,
  host,
  device,
}: {
  token: string;
  host: string;
  device: DeviceWithoutGatewayCreateModel;
}): Promise<void> => {
  try {
    const url = getUrl(host);

    const mappedData = deviceWithoutGatewayCreateToPayload(device);

    const options: AxiosRequestConfig = {
      url,
      method: 'POST',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
      data: { data: mappedData },
    };

    const { data } = (await axios(options)) as AxiosResponse<void>;
    return data;
  } catch (error) {
    throw getErrorFormat(error);
  }
};
