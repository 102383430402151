import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { DeviceWithoutGatewayModel, DeviceWithoutGatewayParameterModel, DeviceWithoutGatewaySensorModel } from 'core/domain/devices/models';
import {
  WaterMeterWm1000nbFormLiterals,
  WaterMeterWm1000nbFormValues,
  WaterMeterWm1000nbFormValuesNames,
  WaterMeterWm1000nbOptionsModel,
} from './WaterMeterWm1000nbForm';

const INTERVAL_TIME_UNITS = 'minutes';

export enum WaterMeterWm1000nbFormSensorValuesNames {
  COUNT_UP = 'count_up',
  COUNT_DOWN = 'count_down',
  BATTERY = 'battery',
}

export enum WaterMeterWm1000nbFormSensorTypeValuesNames {
  CUBIC_METERS_ACCUMULATED = 'CUBICMETERS_ACCUMULATED',
  BATTERY = 'BATTERY',
}

export const useWaterMeterWm1000nb = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm<WaterMeterWm1000nbFormValues>();
  const [parameters, setParameters] = useState<DeviceWithoutGatewayModel | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [device, setDevice] = useState<DeviceWithoutGatewayModel>();

  const literals: WaterMeterWm1000nbFormLiterals = {
    deviceSectionTitle: t('_CREATE_DEVICE_WATER_METER_WM1000NB_SECTION_TITLE'),
    ipLabel: t('_CREATE_DEVICE_WATER_METER_WM1000NB_IP_LABEL'),
    ipPlaceholder: t('_CREATE_DEVICE_WATER_METER_WM1000NB_IP_PLACEHOLDER'),
    portLabel: t('_CREATE_DEVICE_WATER_METER_WM1000NB_PORT_LABEL'),
    portPlaceholder: t('_CREATE_DEVICE_WATER_METER_WM1000NB_PORT_PLACEHOLDER'),
    intervalTimeLabel: t('_CREATE_DEVICE_WATER_METER_WM1000NB_INTERVAL_TIME_LABEL'),
    intervalTimePlaceholder: t('_CREATE_DEVICE_WATER_METER_WM1000NB_INTERVAL_TIME_PLACEHOLDER'),
    intervalNumberLabel: t('_CREATE_DEVICE_WATER_METER_WM1000NB_INTERVAL_NUMBER_LABEL'),
    intervalNumberPlaceholder: t('_CREATE_DEVICE_WATER_METER_WM1000NB_INTERVAL_NUMBER_PLACEHOLDER'),
    secretKeyLabel: t('_CREATE_DEVICE_WATER_METER_WM1000NB_SECRET_KEY_LABEL'),
    secretKeyPlaceholder: t('_CREATE_DEVICE_WATER_METER_WM1000NB_SECRET_KEY_PLACEHOLDER'),
    serialNumberLabel: t('_CREATE_DEVICE_WATER_METER_WM1000NB_SERIAL_NUMBER_LABEL'),
    serialNumberPlaceholder: t('_CREATE_DEVICE_WATER_METER_WM1000NB_SERIAL_NUMBER_PLACEHOLDER'),
    nameLabel: t('_CREATE_DEVICE_WATER_METER_WM1000NB_NAME_LABEL'),
    namePlaceholder: t('_CREATE_DEVICE_WATER_METER_WM1000NB_NAME_PLACEHOLDER'),
    roomLabel: t('_CREATE_DEVICE_WATER_METER_WM1000NB_ROOM_LABEL'),
    roomPlaceholder: t('_CREATE_DEVICE_WATER_METER_WM1000NB_ROOM_PLACEHOLDER'),
    sensorSectionTitle: t('_CREATE_DEVICE_WATER_METER_WM1000NB_SENSOR_SECTION_TITLE'),
    sensorSectionDescription: t('_CREATE_DEVICE_WATER_METER_WM1000NB_SENSOR_SECTION_DESCRIPTION'),
    countUpSensorText: t('_CREATE_DEVICE_WATER_METER_WM1000NB_SENSOR_COUNT_UP_TEXT'),
    countUpSensorNameLabel: t('_CREATE_DEVICE_WATER_METER_WM1000NB_SENSOR_COUNT_UP_NAME_LABEL'),
    countUpSensorNamePlaceholder: t('_CREATE_DEVICE_WATER_METER_WM1000NB_SENSOR_COUNT_UP_NAME_PLACEHOLDER'),
    countUpSensorRoomLabel: t('_CREATE_DEVICE_WATER_METER_WM1000NB_SENSOR_COUNT_UP_ROOM_LABEL'),
    countUpSensorRoomPlaceholder: t('_CREATE_DEVICE_WATER_METER_WM1000NB_SENSOR_COUNT_UP_ROOM_PLACEHOLDER'),
    countDownSensorText: t('_CREATE_DEVICE_WATER_METER_WM1000NB_SENSOR_COUNT_DOWN_TEXT'),
    countDownSensorNameLabel: t('_CREATE_DEVICE_WATER_METER_WM1000NB_SENSOR_COUNT_DOWN_NAME_LABEL'),
    countDownSensorNamePlaceholder: t('_CREATE_DEVICE_WATER_METER_WM1000NB_SENSOR_COUNT_DOWN_NAME_PLACEHOLDER'),
    countDownSensorRoomLabel: t('_CREATE_DEVICE_WATER_METER_WM1000NB_SENSOR_COUNT_DOWN_ROOM_LABEL'),
    countDownSensorRoomPlaceholder: t('_CREATE_DEVICE_WATER_METER_WM1000NB_SENSOR_COUNT_DOWN_ROOM_PLACEHOLDER'),
    batterySensorText: t('_CREATE_DEVICE_WATER_METER_WM1000NB_SENSOR_BATTERY_TEXT'),
    batterySensorNameLabel: t('_CREATE_DEVICE_WATER_METER_WM1000NB_SENSOR_BATTERY_NAME_LABEL'),
    batterySensorNamePlaceholder: t('_CREATE_DEVICE_WATER_METER_WM1000NB_SENSOR_BATTERY_NAME_PLACEHOLDER'),
    batterySensorRoomLabel: t('_CREATE_DEVICE_WATER_METER_WM1000NB_SENSOR_BATTERY_ROOM_LABEL'),
    batterySensorRoomPlaceholder: t('_CREATE_DEVICE_WATER_METER_WM1000NB_SENSOR_BATTERY_ROOM_PLACEHOLDER'),
    requiredFieldTextLegend: t('required_field'),
    requiredFieldText: t('required_field'),
  };

  const transformConfigParameters = (): DeviceWithoutGatewayParameterModel[] => {
    const ip: string | null = form.getFieldValue(WaterMeterWm1000nbFormValuesNames.COMMUNICATION_PLATFORM_IP);
    const port: string | null = form.getFieldValue(WaterMeterWm1000nbFormValuesNames.COMMUNICATION_PLATFORM_PORT);
    const intervalTime: string | null = form.getFieldValue(WaterMeterWm1000nbFormValuesNames.REPORTING_INTERVAL_TIME);
    const intervalNumber: string | null = form.getFieldValue(WaterMeterWm1000nbFormValuesNames.REPORTING_MEASUREMENTS_NUMBER);
    const ipParameter: DeviceWithoutGatewayParameterModel = {
      data: [{ value: !!ip ? ip : null, unit: null }],
      name: WaterMeterWm1000nbFormValuesNames.COMMUNICATION_PLATFORM_IP,
      multiple: false,
      required: false,
    };
    const portParameter: DeviceWithoutGatewayParameterModel = {
      data: [{ value: !!port ? port : null, unit: null }],
      name: WaterMeterWm1000nbFormValuesNames.COMMUNICATION_PLATFORM_PORT,
      multiple: false,
      required: false,
    };
    const intervalNumberParameter: DeviceWithoutGatewayParameterModel = {
      data: [{ value: !!intervalNumber ? intervalNumber : null, unit: null }],
      name: WaterMeterWm1000nbFormValuesNames.REPORTING_MEASUREMENTS_NUMBER,
      multiple: false,
      required: true,
    };
    const intervalTimeParameter: DeviceWithoutGatewayParameterModel = {
      data: [{ value: !!intervalTime ? intervalTime : null, unit: INTERVAL_TIME_UNITS }],
      name: WaterMeterWm1000nbFormValuesNames.REPORTING_INTERVAL_TIME,
      multiple: false,
      required: true,
    };

    return [ipParameter, portParameter, intervalNumberParameter, intervalTimeParameter];
  };

  const transformBatterySensor = (sensor?: DeviceWithoutGatewaySensorModel): DeviceWithoutGatewaySensorModel => {
    const batteryName = form.getFieldValue(WaterMeterWm1000nbFormValuesNames.SENSOR_BATTERY_NAME);
    const batteryRoom = form.getFieldValue(WaterMeterWm1000nbFormValuesNames.SENSOR_BATTERY_ROOM);

    return {
      label: WaterMeterWm1000nbFormSensorValuesNames.BATTERY,
      sensorType: WaterMeterWm1000nbFormSensorTypeValuesNames.BATTERY,
      sensorId: !!sensor ? sensor.sensorId : '',
      sensorUuid: !!sensor ? sensor.sensorUuid : '',
      name: !!batteryName ? batteryName : null,
      room: !!batteryRoom ? batteryRoom : null,
    };
  };

  const transformCountUpSensor = (sensor?: DeviceWithoutGatewaySensorModel): DeviceWithoutGatewaySensorModel => {
    const countUpName = form.getFieldValue(WaterMeterWm1000nbFormValuesNames.SENSOR_COUNT_UP_NAME);
    const countUpRoom = form.getFieldValue(WaterMeterWm1000nbFormValuesNames.SENSOR_COUNT_UP_ROOM);

    return {
      label: WaterMeterWm1000nbFormSensorValuesNames.COUNT_UP,
      sensorType: WaterMeterWm1000nbFormSensorTypeValuesNames.CUBIC_METERS_ACCUMULATED,
      sensorId: !!sensor ? sensor.sensorId : '',
      sensorUuid: !!sensor ? sensor.sensorUuid : '',
      name: !!countUpName ? countUpName : null,
      room: !!countUpRoom ? countUpRoom : null,
    };
  };

  const transformCountDownSensor = (sensor?: DeviceWithoutGatewaySensorModel): DeviceWithoutGatewaySensorModel => {
    const countDownName = form.getFieldValue(WaterMeterWm1000nbFormValuesNames.SENSOR_COUNT_DOWN_NAME);
    const countDownRoom = form.getFieldValue(WaterMeterWm1000nbFormValuesNames.SENSOR_COUNT_DOWN_ROOM);

    return {
      label: WaterMeterWm1000nbFormSensorValuesNames.COUNT_DOWN,
      sensorType: WaterMeterWm1000nbFormSensorTypeValuesNames.CUBIC_METERS_ACCUMULATED,
      sensorId: !!sensor ? sensor.sensorId : '',
      sensorUuid: !!sensor ? sensor.sensorUuid : '',
      name: !!countDownName ? countDownName : null,
      room: !!countDownRoom ? countDownRoom : null,
    };
  };

  const sensorsManager = {
    [WaterMeterWm1000nbFormSensorValuesNames.COUNT_UP]: transformCountUpSensor,
    [WaterMeterWm1000nbFormSensorValuesNames.COUNT_DOWN]: transformCountDownSensor,
    [WaterMeterWm1000nbFormSensorValuesNames.BATTERY]: transformBatterySensor,
  };

  const transformSensors = (sensors?: DeviceWithoutGatewaySensorModel[]): DeviceWithoutGatewaySensorModel[] => {
    const transformedSensors = !!sensors
      ? sensors.map((sensor) => sensorsManager[sensor.label as WaterMeterWm1000nbFormSensorValuesNames](sensor))
      : [];
    const batterySensor = transformBatterySensor();
    const countDownSensor = transformCountDownSensor();
    const countUpSensor = transformCountUpSensor();
    const newSensors = [batterySensor, countDownSensor, countUpSensor];

    return !!sensors?.length ? transformedSensors : newSensors;
  };

  const transformValuesToBeSent = (selectedDevice?: DeviceWithoutGatewayModel): DeviceWithoutGatewayModel => {
    const name = form.getFieldValue(WaterMeterWm1000nbFormValuesNames.NAME);
    const room = form.getFieldValue(WaterMeterWm1000nbFormValuesNames.ROOM);
    const secretKey = form.getFieldValue(WaterMeterWm1000nbFormValuesNames.SECRET_KEY);
    const serialNumber = form.getFieldValue(WaterMeterWm1000nbFormValuesNames.SERIAL_NUMBER);
    const transformedConfigParameters = transformConfigParameters();
    const transformedSensors = transformSensors(selectedDevice?.sensors);

    return {
      assetId: !!selectedDevice ? selectedDevice.assetId : '',
      deviceType: !!selectedDevice ? selectedDevice.deviceType : '',
      deviceUuid: !!selectedDevice ? selectedDevice.deviceUuid : '',
      name,
      room,
      secretKey,
      serialNumber,
      configParameters: transformedConfigParameters,
      sensors: transformedSensors,
    };
  };

  const onFinish = () => {
    const transformedValues = transformValuesToBeSent(device);
    setParameters(transformedValues);
  };

  const onFinishFailed = () => {
    setError(true);
    setError(false);
  };

  const resetWaterMeterWm1000nbValues = () => {
    form.resetFields();
    setDevice(undefined);
    setParameters(null);
  };

  const getWaterMeterWm1000nbFormValues = (selectedDevice: DeviceWithoutGatewayModel) => {
    setDevice(selectedDevice);
    const { name, room, secretKey, serialNumber, configParameters, sensors } = selectedDevice;
    const countUpSensor = sensors.find((sensor) => sensor.label === WaterMeterWm1000nbFormSensorValuesNames.COUNT_UP);
    const countDownSensor = sensors.find((sensor) => sensor.label === WaterMeterWm1000nbFormSensorValuesNames.COUNT_DOWN);
    const batterySensor = sensors.find((sensor) => sensor.label === WaterMeterWm1000nbFormSensorValuesNames.BATTERY);
    const platformIpParameter = configParameters?.find(
      (parameter) => parameter.name === WaterMeterWm1000nbFormValuesNames.COMMUNICATION_PLATFORM_IP
    );
    const platformPortParameter = configParameters?.find(
      (parameter) => parameter.name === WaterMeterWm1000nbFormValuesNames.COMMUNICATION_PLATFORM_PORT
    );
    const intervalTimeParameter = configParameters?.find(
      (parameter) => parameter.name === WaterMeterWm1000nbFormValuesNames.REPORTING_INTERVAL_TIME
    );
    const intervalNumberParameter = configParameters?.find(
      (parameter) => parameter.name === WaterMeterWm1000nbFormValuesNames.REPORTING_MEASUREMENTS_NUMBER
    );

    form.setFieldsValue({
      name,
      room,
      secret_key: secretKey,
      serial_number: serialNumber,
      communication_platform_ip: platformIpParameter ? String(platformIpParameter.data[0].value) : '',
      communication_platform_port: platformPortParameter ? String(platformPortParameter.data[0].value) : '',
      reporting_interval_time: intervalTimeParameter ? String(intervalTimeParameter.data[0].value) : '',
      reporting_measurements_number: intervalNumberParameter ? String(intervalNumberParameter.data[0].value) : '',
      battery_name: batterySensor ? batterySensor.name : '',
      battery_room: batterySensor ? batterySensor.room : '',
      count_up_name: countUpSensor ? countUpSensor.name : '',
      count_up_room: countUpSensor ? countUpSensor.room : '',
      count_down_name: countDownSensor ? countDownSensor.name : '',
      count_down_room: countDownSensor ? countDownSensor.room : '',
    });
  };

  const waterMeterWm1000nbOptions: WaterMeterWm1000nbOptionsModel = {
    form,
    literals,
    onFinish,
    onFinishFailed,
  };

  return {
    waterMeterWm1000nbOptions,
    waterMeterWm1000nbParameters: parameters,
    resetWaterMeterWm1000nbValues,
    getWaterMeterWm1000nbFormValues,
    errorCreatingWaterMeterWm1000nb: error,
  };
};
