import { FC } from 'react';
import DrawerAtom, { DrawerAtomProps } from 'components/atoms/DrawerAtom';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import { Icon } from 'components/atoms/Icons';
import { CreateDeviceOptionsModel } from 'components/pages/AssetPage/Tabs/TabDevices/resources/useCreateDeviceWithoutGateway';
import SelectAtom from 'components/atoms/SelectAtom';
import DeviceTypesVisorManager from 'components/pages/AssetPage/Tabs/TabDevices/resources/DeviceTypesVisorManager';
import './styles.scss';

export interface CreateDeviceLiteralsType {
  createDeviceButtonText: string;
  title: string;
  typeSectionTitle: string;
  mainButtonText: string;
  secondaryButtonText: string;
  infoText: string;
  deviceTypeLabel: string;
  deviceTypePlaceholder: string;
}

export interface Props extends DrawerAtomProps {
  options: CreateDeviceOptionsModel;
}

const ICON_SIZE = 24;
const DEVICE_SECTION_NUMBER = 2;
const SENSORS_SECTION_NUMBER = 3;

const CreateDeviceWithoutGatewayDrawer: FC<Props> = ({ open, onClose, title, options }) => {
  const {
    createDeviceLiterals,
    devicesTypes,
    formType,
    waterMeterWm1000nbOptions,
    onChangeDeviceType,
    onAddDevice,
    hasDevicesTypes,
    loading,
    creating,
  } = options
  const { mainButtonText, secondaryButtonText, infoText, deviceTypePlaceholder, typeSectionTitle, deviceTypeLabel } = createDeviceLiterals;

  return (
    <DrawerAtom
      className={'CreateDeviceWithoutGatewayDrawer__wrapper'}
      open={open}
      onClose={onClose}
      size={'large'}
      title={<span className={'CreateDeviceWithoutGatewayDrawer__title'}>{title}</span>}
    >
      <main className={'CreateDeviceWithoutGatewayDrawer__content-wrapper'}>
        <div className={'CreateDeviceWithoutGatewayDrawer__info'}>
          <div className={'CreateDeviceWithoutGatewayDrawer__info-icon'}>
            <Icon.InfoCircleFilled style={{ fontSize: ICON_SIZE }} />
          </div>
          <div>
            <p className={'CreateDeviceWithoutGatewayDrawer__info-text'}>{infoText}</p>
          </div>
        </div>
        <div className={'CreateDeviceWithoutGatewayDrawer__text-wrapper'}>
          <p className={'CreateDeviceWithoutGatewayDrawer__text'}>{`1. ${typeSectionTitle}`}</p>
        </div>
        <div className={'CreateDeviceWithoutGatewayDrawer__type-wrapper'}>
          <div className={'CreateDeviceWithoutGatewayDrawer__type-selector'}>
            <label htmlFor={'deviceTypeSelector'}>{deviceTypeLabel}</label>
            <SelectAtom
              value={formType}
              placeholder={deviceTypePlaceholder}
              onChange={onChangeDeviceType}
              disabled={!hasDevicesTypes}
              loading={loading}
              allowClear
              id={'deviceTypeSelector'}
            >
              {hasDevicesTypes && devicesTypes.map(({ deviceType }) => (
                <SelectAtom.Option key={deviceType} value={deviceType}>{deviceType}</SelectAtom.Option>
              ))}
            </SelectAtom>
          </div>
        </div>
        <div className={'CreateDeviceWithoutGatewayDrawer__form'}>
          {!!formType && (
            <DeviceTypesVisorManager
              formType={formType}
              waterMeterWm1000nbOptions={waterMeterWm1000nbOptions}
              deviceSectionNumber={DEVICE_SECTION_NUMBER}
              sensorsSectionNumber={SENSORS_SECTION_NUMBER}
            />
          )}
        </div>
      </main>
      <footer>
        <DefaultButton onClick={onClose} title={secondaryButtonText} />
        <PrimaryButton loading={creating} title={mainButtonText} onClick={onAddDevice} />
      </footer>
    </DrawerAtom>
  );
}

export default CreateDeviceWithoutGatewayDrawer;